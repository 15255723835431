import { render, staticRenderFns } from "./CmsMediclub3Block4.vue?vue&type=template&id=b5bd3100&scoped=true&"
import script from "./CmsMediclub3Block4.vue?vue&type=script&lang=js&"
export * from "./CmsMediclub3Block4.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclub3Block4.vue?vue&type=style&index=0&id=b5bd3100&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5bd3100",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CmsMediclub3Heading: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v3/CmsMediclub3Heading.vue').default,BaseImage: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,BaseLink: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseLink.vue').default})
