import { render, staticRenderFns } from "./CmsMediclub3Block2.vue?vue&type=template&id=c47a6b78&scoped=true&"
import script from "./CmsMediclub3Block2.vue?vue&type=script&lang=js&"
export * from "./CmsMediclub3Block2.vue?vue&type=script&lang=js&"
import style0 from "./CmsMediclub3Block2.vue?vue&type=style&index=0&id=c47a6b78&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c47a6b78",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseImage.vue').default,CmsMediclub3Auth: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/cms/mediclub/v3/CmsMediclub3Auth.vue').default})
